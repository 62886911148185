import React from "react"
import { graphql } from "gatsby"
import { Cards, Hero, SiteMetadata } from "../components"
import { Layout } from "../layouts/Layout"

const Index = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata
        title="Sweetie’s Baked Goods • Lake Tahoe"
        description="Check the most popular travel destinations in Europe."
        image={data.hero.url}
      />

      <Hero
        image={data.hero}
        title="Sweetie’s Baked Goods"
        description="Custom order home bakery in North Lake Lake Tahoe, with local delivery available to the Reno/Tahoe/Carson City area."
      />

      <Cards nodes={data.items.nodes} />
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery($tableName: String!) {
    hero: file(relativePath: { eq: "hero-pin.jpg" }) {
      ...HeroImageFragment
    }
    items: allAirtable(filter: { table: { eq: $tableName } }) {
      nodes {
        data {
          image {
            ...CardImageFragment
          }
          name
          position
          price
          slug
          summary
        }
      }
    }
  }
`
export default Index
